import { isObjEqual } from '../utils/record'
import { pick } from '../utils/record'
import { useMemo, useCallback, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation, RouteComponentProps } from 'react-router-dom'

import { parseRawSearchParams, normalizeSearchParams, getUpdatedPathname } from 'utils/routing'

import * as I from 'types'

export type Route = ReturnType<typeof useRoute>
export type RouteUrlParams<T extends keyof I.SearchParams | undefined = undefined> = {
  searchParams: T extends keyof I.SearchParams ? Pick<I.SearchParams, T> : I.SearchParams
  changeSearchParams: (
    params: T extends keyof I.SearchParams ? Pick<I.SearchParams, T> : I.SearchParams,
    type?: 'replace' | 'push'
  ) => void
}

export function useURLParams<T extends keyof I.SearchParams>(
  paramPrefix?: string,
  subscribeToParams?: T[] /* by default we subscribed to all params changes */
): {
  searchParams: Pick<I.SearchParams, T>
  changeSearchParams: (params: Pick<I.SearchParams, T>, type?: 'replace' | 'push') => void
} {
  const isDidMountRef = useRef(true)

  const history = useHistory()
  const location = useLocation()
  const search = location.search

  const [rawSearchParams, setRawSearchParams] = useState<{ [param: string]: string }>(() => {
    const parsed = parseRawSearchParams(search, paramPrefix).scopedRawSearchParams
    return subscribeToParams ? pick(parsed, subscribeToParams as any) : parsed
  })

  const changeSearchParams = useCallback(
    (params: Pick<I.SearchParams, T>, changeType: 'push' | 'replace' = 'push') =>
      changeSearchParamsFactory(history, paramPrefix)(params, changeType),
    /* history context change is irrelevant for updated */
    [paramPrefix]
  )

  useEffect(() => {
    if (isDidMountRef.current) {
      isDidMountRef.current = false
      return
    }

    const parsed = parseRawSearchParams(search, paramPrefix).scopedRawSearchParams
    const updatedRawSearchParams = subscribeToParams ? pick(parsed, subscribeToParams) : parsed

    if ((paramPrefix || subscribeToParams) && isObjEqual(rawSearchParams, updatedRawSearchParams)) {
      return
    }

    setRawSearchParams(updatedRawSearchParams)

    /* The `rawSearchParams` will be hoisted correctly because of "search" dependency */
  }, [search, paramPrefix])

  return useMemo(
    () => ({ changeSearchParams, searchParams: normalizeSearchParams(rawSearchParams) }),
    [changeSearchParams, rawSearchParams]
  )
}

/*  Usage of `useURLParams` is preferable for better optimization qualities */
export default function useRoute<T extends keyof I.SearchParams>(
  paramPrefix?: string,
  subscribeToParams?: T[] /* by default we subscribed to all params changes */
): {
  searchParams: Pick<I.SearchParams, T>
  history: RouteComponentProps['history']
  changeSearchParams: (params: Pick<I.SearchParams, T>, type?: 'replace' | 'push') => void
} {
  const history = useHistory()
  const location = useLocation()

  const search = location.search

  const searchParams = useMemo(() => {
    const parsed = parseRawSearchParams(search, paramPrefix).scopedRawSearchParams
    const rawSearchParams = subscribeToParams ? pick(parsed, subscribeToParams as any) : parsed

    return normalizeSearchParams(rawSearchParams)
  }, [search, paramPrefix, subscribeToParams])

  const changeSearchParams = useCallback(
    (params: Pick<I.SearchParams, T>, changeType: 'push' | 'replace' = 'push') =>
      changeSearchParamsFactory(history, paramPrefix)(params, changeType),
    [paramPrefix, history]
  )

  return useMemo(() => {
    return {
      changeSearchParams,
      searchParams,
      history,
    }
  }, [history, changeSearchParams, searchParams])
}

function changeSearchParamsFactory(
  history: { [k in 'push' | 'replace']: (path: string) => void },
  paramPrefix?: string
) {
  return (params: I.SearchParams, changeType: 'push' | 'replace' = 'push') => {
    history[changeType](getUpdatedPathname(params, paramPrefix))
  }
}
