import React from 'react'

import { useURLParams } from '../hooks/useRoute'
import http from '../http-client'

type LogsResponse = ReturnType<typeof http.fetch.logs> extends Promise<infer T> ? T : never

export function LogsPage() {
  const { searchParams } = useURLParams()
  const [data, setData] = React.useState<LogsResponse>()

  React.useEffect(() => {
    http.fetch.logs({ daysBefore: searchParams.days_before || undefined }).then((x) => setData(x))
  }, [])

  if (data === undefined) {
    return <Wrapper>Loading...</Wrapper>
  }

  const dataDisplay = JSON.stringify(data)

  return <Wrapper>{dataDisplay}</Wrapper>
}

function Wrapper(p: { children: React.ReactNode }) {
  return (
    <div style={{ padding: '64px', background: '#000', color: '#eee', fontSize: '16px', height: '100%' }}>
      {p.children}
    </div>
  )
}
