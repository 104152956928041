import { Effect } from 'effector'
import { DefaultTheme } from 'styled-components'
import { Model } from '../../server/src/models'
import { EventPayload, AuthToken } from '../../server/src/types'

export declare namespace AppState {
  // Managed by `src/local-storage.ts`
  type Auth = null | { id: Model.UserId; username: string; token: AuthToken; rootAimId: Model.AimId }

  // Managed by `src/features/treeBoard.ts`
  type TreeBoard = {
    fetched: boolean
    aims: Map<Model.AimId, Model.Aim>
    controlPanel: {
      rootAimId: RootAimId | null
      selectedAimId: Model.AimId | null
      cutAimId: Model.AimId | null
      prevSelectedChildren: Model.AimId | null
      modifyPhase: ModifyPhase
    }
  }

  // Managed by `src/features/page.ts`
  export type TreeBoardPage = {
    detailsAimId: Model.AimId | null
  }

  // Managed by `src/features/timeTracker.ts`
  export type TimeTracker = {
    fetched: boolean
    trackingAimId: Model.AimId | null
    trackingTimePeriodId: Model.TimePeriodId | null
    timePeriodById: Map<Model.TimePeriod['id'], Model.TimePeriod>
    timePeriodIdsByLinkedEntityId: Map<Model.Aim['id'], Model.TimePeriodId[]>
  }

  // Managed by `src/features/settings.ts`
  export type Settings = {
    // TODO rename to `selectedProfileId`
    selectedProfile: SettingProfileId
    profiles: {
      [profileName in SettingProfileId]: DefaultTheme
    }
  }
}

// Pass "null" explicitly if you want to remove parameter from query string
export type SearchParams = {
  detailsAimId?: Model.AimId | null
  days_before?: string | null
}

export type SearchParamsValidators =
  | { type: 'string'; shouldMatch?: RegExp; allowEmpty?: boolean }
  | { type: 'boolean' } // query: 'true'; parsed type: boolean
  | { type: 'number'; min?: number; max?: number }
  | { type: 'filter' } // query: '["262","111","0302"],["263","1234"]'; parsed type: Array<string[]>

export type RGBAColor = {
  r: number
  g: number
  b: number
  a: number
}

export type TreeNode = {
  name: string
  children?: TreeNode[]
}

export type SettingProfileId = string
export type RootAimId = Model.AimId & { __brand: 'RootAimId' }

export type Direction = 'up' | 'right' | 'down' | 'left'
export type TabKeySelection = 'next' | 'prev'

export type DnDItem = {
  id: Model.AimId
  type: 'aim'
}

/// TODO: consider using `'none'` string value instead of `null`
export type ModifyPhase = 'editing' | 'removing' | 'childCreating' | 'parentCreating' | 'cutting' | null

export type Panel = {
  settings: boolean
}

export type ComponentShowcase = [
  componentFn: () => JSX.Element,
  subRoutePath: string,
  info: [title: string, description: string]
]

export const exhaustiveCheck = (x: never) => x

export type { Effect, Model, EventPayload, AuthToken }
