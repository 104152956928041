import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'

import withAuth, { WithAuthInjectedProps } from 'hocs/withAuth'
import DataProvider from 'DataProvider'

import NotFoundPage from './pages/NotFoundPage'
import EmptyPage from './pages/EmptyPage'
import TreeBoardPage from './pages/TreeBoardPage'

import LoginPage from './pages/auth/LoginPage'
import SignUpPage from './pages/auth/SignUpPage'
import { LogsPage } from './pages/logs'

import ShowcasePage from './pages/ShowcasePage'

function HomePage(p: WithAuthInjectedProps) {
  return <DataProvider element={<Redirect to={`/branch/${p.auth.rootAimId}`} />} />
}

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* development */}
        <Route path="/empty" component={EmptyPage} />
        <Route path="/showcase" component={ShowcasePage} />
        <Route path="/logs" component={withAuth(LogsPage)} />

        {/* app */}
        <Route exact path="/" component={withAuth(HomePage)} />
        <Route path="/branch/:branchId" component={withAuth(TreeBoardPage)} />

        {/* auth */}
        <Route path="/login" component={LoginPage} />
        <Route path="/sign-up" component={SignUpPage} />

        {/* common */}
        <Route path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  )
}
