// TODO: rename to `SignInPage.tsx` to be consistent with `SignUpPage.tsx`
//

import React, { useCallback } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import './LoginPage.scss'

import { manageAuth } from 'local-storage'
import http from 'http-client'
import { RedirectState } from 'hocs/withAuth'

import UnauthLayout from 'components/UnauthLayout'
import { FIELD_NAMES } from 'components/LoginForm/LoginForm.cfg'
import LoginForm, { LoginConfig } from 'components/LoginForm'

import * as I from 'types'

type Props = RouteComponentProps<null, any, null | RedirectState>

const REDIRECT_TO = '/'
const CN = 'LoginPage'

export default function LoginPage(p: Props) {
  const {
    history: { replace },
  } = p

  const isAuth = !!manageAuth.get()
  const redirectAfterLoginTo = p.location.state?.path

  const handleSubmit: React.ComponentProps<typeof LoginForm>['handleSubmit'] = useCallback(
    async (credentials) => {
      const signInResponse = await http.auth.signIn(credentials)

      if (signInResponse.status === 'exception') {
        switch (signInResponse.data) {
          case 'Incorrect password':
            throw { [FIELD_NAMES.password]: signInResponse.data }
          case 'Incorrect username':
            throw { [FIELD_NAMES.username]: signInResponse.data }
          default:
            I.exhaustiveCheck(signInResponse)
        }
      }

      manageAuth.set(signInResponse.data)
      setTimeout(() => replace(redirectAfterLoginTo || REDIRECT_TO))
    },
    [redirectAfterLoginTo, replace]
  )

  if (isAuth) {
    return <Redirect to={REDIRECT_TO} />
  }

  return (
    <UnauthLayout className={CN}>
      <div className={`${CN}__login-form`}>
        <LoginForm schema={LoginConfig.FORM_SCHEMA} handleSubmit={handleSubmit} />
      </div>
    </UnauthLayout>
  )
}
